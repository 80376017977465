import { useState, FC, useMemo } from 'react';

import { isEmpty } from 'lodash';

import { IBudget, TBudgetStatus } from 'modules/checkout/domain/entities';
import { getMissingCustomerFields } from 'modules/checkout/presentation/helpers';
import { ICustomer, TPaymentMethodType } from 'shared/domain/entities';
import { useLocale } from 'shared/presentation/contexts';

import useDigitalSigningAvailability from '../../hooks/useDigitalSigningAvailability';
import { PaymentLinkContext } from '../../hooks/usePaymentLink';

export const PaymentLinkProvider: FC = ({ children }) => {
  const { language } = useLocale();

  const [budget, setBudget] = useState<IBudget | null>(null);
  const [selectedPaymentMethodType, setSelectedPaymentMethodType] =
    useState<TPaymentMethodType>('CREDIT_CARD');
  const digitalSigning = useDigitalSigningAvailability();

  const customerNeedsToFinishRegistration = useMemo(() => {
    if (!budget || !budget.customer) return false;

    const customerMeta = getMissingCustomerFields(budget.customer, language);
    return !!customerMeta && !isEmpty(customerMeta.fields);
  }, [budget, language]);

  const updateCustomer = (newCustomerData: Partial<ICustomer>) => {
    setBudget(currentBudget => ({
      ...currentBudget!,
      customer: {
        ...currentBudget!.customer,
        ...newCustomerData,
      },
    }));
  };

  const updateBudgetStatus = (status: TBudgetStatus) => {
    setBudget(currentBudget => ({
      ...currentBudget!,
      status,
    }));
  };

  return (
    <PaymentLinkContext.Provider
      value={{
        budget,
        selectedPaymentMethodType,
        digitalSigningEnabled: digitalSigning.enabled,

        customerNeedsToFinishRegistration,

        setBudget,
        setSelectedPaymentMethodType,

        updateCustomer,
        updateBudgetStatus,
      }}
    >
      {children}
    </PaymentLinkContext.Provider>
  );
};
