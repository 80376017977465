import styled, { css } from 'styled-components';

interface IContainer {
  disabled?: boolean;
  hasError?: boolean;
  value?: string;
  textTransform?: 'uppercase' | 'lowercase';
  backgroundColor?: string;
}

export const Container = styled.div<IContainer>`
  ${({
    theme,
    disabled,
    hasError,
    value,
    textTransform,
    backgroundColor,
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.layout.spacing(1)};

    label {
      cursor: pointer;
      font-size: ${theme.typography.sizes.body1};
      width: fit-content;
    }

    > div {
      ${disabled &&
      css`
        opacity: 0.7;
        pointer-events: none;
      `}

      flex: 1;
      display: flex;
      align-items: center;
      transition: all 0.2s;
      border-radius: 12px;
      overflow: hidden;
      background-color: ${backgroundColor || theme.palette.neutral[100]};
      padding-right: ${theme.layout.spacing(3)};
      gap: ${theme.layout.spacing(2)};
      border: 1px solid
        ${hasError ? theme.palette.error.main : theme.palette.neutral[100]};

      &:focus-within {
        border-color: ${theme.palette.primary.main};
      }

      input {
        width: 100%;
        padding: ${theme.layout.spacing(2, 0, 2, 3)};
        font-size: ${theme.typography.sizes.body1};
        color: ${theme.palette.text.main};
        border: none;
        outline: none;
        background-color: transparent;
        transition: all 0.2s;

        ::placeholder {
          color: ${theme.palette.text.light};
        }

        ${value &&
        textTransform &&
        css`
          text-transform: ${textTransform};
        `}
      }
    }

    > span {
      color: ${theme.palette.error.main};
      font-size: ${theme.typography.sizes.caption};
    }

    svg {
      color: ${hasError
        ? theme.palette.error.main
        : value
        ? theme.palette.primary.main
        : theme.palette.neutral[500]};
      font-size: 1.2rem;
      transition: all 0.2s;
    }
  `}
`;
