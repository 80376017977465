import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

import { IBudget, TBudgetStatus } from 'modules/checkout/domain/entities';
import { ICustomer, TPaymentMethodType } from 'shared/domain/entities';

interface IPaymentLinkContext {
  budget: IBudget | null;
  selectedPaymentMethodType: TPaymentMethodType;
  digitalSigningEnabled: boolean;

  customerNeedsToFinishRegistration: boolean;

  setBudget(budget: IBudget | null): void;
  setSelectedPaymentMethodType(type: TPaymentMethodType): void;

  updateCustomer(newCustumerData: Partial<ICustomer>): void;
  updateBudgetStatus(status: TBudgetStatus): void;
}

export const PaymentLinkContext: React.Context<IPaymentLinkContext> =
  createContext({} as IPaymentLinkContext);

export const usePaymentLink = () => {
  const context = useContext(PaymentLinkContext);

  if (isEmpty(context)) {
    throw new Error(
      'usePaymentLink should be called within a PaymentLinkContext',
    );
  }

  return context;
};
