import { AVAIABLE_UTM_QUERY_PARAMS } from 'constants/avaiableUtmParams';

import {
  createContext,
  useEffect,
  useReducer,
  useContext,
  useMemo,
} from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import axios from 'axios';
import JwtDecode from 'jwt-decode';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useNavigation } from 'shared/presentation/contexts';

import useLogger from '../services/Logger';

const VIALASER_TOKEN = '@vialaser:token';

const ACTIONS = {
  PROCESS_URL: 'process_url',
  LOGOUT: 'logout',
  UPDATE_STATE: 'update_state',
};

export const UTM = {
  SOURCE: 'utm_source',
  MEDIUM: 'utm_medium',
  CAMPAIGN: 'utm_campaign',
  TERM: 'utm_term',
  CONTENT: 'utm_content',
  FACEBOOK_ID: 'fbclid',
  GOOGLE_ID: 'gclid',
};

const TENANCIAS = {
  BRASIL: 'vialaser',
  PARAGUAI: 'vialaserparaguay',
};

const AppContext = createContext({
  dispatch: ({ type }) => undefined,
  userId: undefined,
  globalState: {
    user: {},
    token: undefined,
  },
  dispatch({ type }) {
    return undefined;
  },
});

const initialState = {
  hideMenus: false,
  logo: null,
  user: null,
  token: null,
  tenancia: TENANCIAS.BRASIL,
  banners: null,
};

function AuthProvider(props) {
  const theme = useTheme();
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const { i18n } = useTranslation();
  const logger = useLogger();
  const navigation = useNavigation();

  initialState.tenancia =
    i18n.language === 'pt' ? TENANCIAS.BRASIL : TENANCIAS.PARAGUAI;

  const [state, dispatch] = useReducer((oldState, action) => {
    const newState = {};

    function getNewState(oldState, newState) {
      return { ...oldState, ...newState };
    }

    switch (action.type) {
      case ACTIONS.PROCESS_URL:
        if (window.location.search) {
          const cookies = new Cookies();
          const utms2 = new URL(window.location).searchParams;

          for (const [key, value] of utms2.entries()) {
            if (AVAIABLE_UTM_QUERY_PARAMS.includes(key)) {
              cookies.set(key, value);
            }
          }
        }

        if (localStorage.getItem(VIALASER_TOKEN)) {
          newState.user = {
            id: localStorage.getItem('id'),
            email: localStorage.getItem('email'),
            nome: localStorage.getItem('nome'),
            sexo: localStorage.getItem('sexo'),
            sobrenome: localStorage.getItem('sobrenome'),
            fototipoEcommerce: localStorage.getItem('fototipoEcommerce'),
          };
          newState.token = localStorage.getItem(VIALASER_TOKEN);
        }
        return { ...oldState, ...newState };

      case ACTIONS.LOGOUT:
        delete axios.defaults.headers.common.Authorization;
        makeHTTPProvider().setHeader('AUTHENTICATION', null);
        localStorage.clear();
        getNewState(oldState, { user: null, token: null });
        navigation.refresh();
        return oldState;

      case ACTIONS.UPDATE_STATE:
        return getNewState(oldState, action.newState);
      default:
        break;
    }
  }, initialState);

  function verifyTokenValidation() {
    const token = localStorage.getItem(VIALASER_TOKEN);
    if (token) {
      const decode = JwtDecode(token);
      const timestamp = decode.exp;
      const expDate = new Date(timestamp * 1000);

      if (expDate < Date.now()) {
        localStorage.removeItem(VIALASER_TOKEN);
        localStorage.removeItem('email');
        localStorage.removeItem('nome');
        localStorage.removeItem('sobrenome');
        localStorage.removeItem('sexo');
      }
    }
  }

  useEffect(() => {
    verifyTokenValidation();

    dispatch({ type: ACTIONS.PROCESS_URL });
  }, []);

  const loadBanners = async () => {
    await axios
      .get('/banners')
      .then(response => {
        dispatch({
          type: ACTIONS.UPDATE_STATE,
          newState: { banners: response.data },
        });
      })
      .catch(error => {
        if (process.env.NODE_ENV !== 'test') {
          logger.debug(error);
        }
      });
  };

  useEffect(() => {
    if (i18n.language !== 'pt') return;

    loadBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const userId = useMemo(
    () => get(state, 'user.id', localStorage.getItem('id')),
    [state],
  );

  return (
    <AppContext.Provider
      value={{
        dispatch,
        globalState: state,
        isUpMd,
        userId,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  return useContext(AppContext);
}

export { AppContext, AuthProvider, useAppContext };
